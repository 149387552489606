@font-face {
  font-family: 'brighteon-font-monospace';
  src: local('Roboto Mono'),
    url('../fonts/roboto-mono/robotomono-regular-webfont.woff2') format('woff2'),
    url('../fonts/roboto-mono/robotomono-regular-webfont.woff') format('woff'),
    url('../fonts/roboto-mono/robotomono-regular-webfont.ttf') format('truetype'),
    url('../fonts/roboto-mono/robotomono-regular-webfont.svg#roboto_monoregular') format('svg');
  font-weight: 400;
  font-style: normal;
}
