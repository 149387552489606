.status__action-bar-button {
  margin-right: 4px;
}

.status__action-bar__counter {
  display: inline-flex;
  margin-right: 11px;
  align-items: center;

  &__label {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    width: auto;
    cursor: pointer;
  }
}

.status__origin-replied-post {
  text-decoration: none;
  color: $dark-text-color;

  &:hover {
    text-decoration: underline;
  }
}

.app-body {
  .columns-area {
    &__panels {
      &__pane {
        min-width: 250px;
  
        &__inner {
          width: 100%;
          padding: 0 10px;
        }
      }
  
      &__main {
        max-width: 750px;
        
        &.settings, &.messages-panel {
          max-width: 1120px;
        }
      }
    }
  }
}

.status__origin-replied-post {
  text-decoration: none;
  color: $dark-text-color;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 630px) {
  .columns-area--mobile {
    .status.status-notification{
      min-height: auto;
      font-size: 15px;
    }
  }
}

.status.status-notification {
  .content {
    font-size: 15px;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  bdi {
    display: flex;
  }
}

// .notification.notification-follow {  // Not sure what this css is
//   border-bottom: 1px solid #c0cdd9;
// }
.columns-area--mobile .notification__message {
  padding-bottom: 15px;
}

body {
  background-color: $light1;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body.app-body.layout-single-column {
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
}

.head-text-link {
  font-size: 14px;
  font-weight: 500;
  color: $dark2;
  padding-right: 15px;
  line-height: 36px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.head-icon-link > div {
  background-color: $light2;
  border-radius: 50%;
}

.column-link__icon {
  margin-right: 0;
}

.brighteon.compose-form {
  border: 1px solid $medium1;
  border-radius: 4px;
  button.icon-button {
    &:hover {
      background: none;
    }
    &:active {
      background: none;
    }
    &:focus {
      background: none;
    }
  }

  .button-icon {
    background-color: $trans1;
    border: none 0px;
    outline: none 0px;
    border-radius: 50%;
    position: relative;

    &:hover, &:active, &:focus {
      background-color: $light2;
    }
  }

  .emoji-picker-dropdown {
    position: relative;
  }

  .emoji-button {
    padding: 0;
  }

  .privacy-dropdown.active .privacy-dropdown__value.active {
    background: none;
  }

  .character-counter__wrapper {
    margin-left: auto;
  }

  .compose-form__publish-button-wrapper {
    padding-top: 0;
  }

  .compose-form__buttons-wrapper {
    padding: 0;
  }

  textarea {
    min-height: 30px;
  }
}
  
/* Icon Area */
.icon-account-settings { background-image: url('../images/brighteon-social/Icon-Settings-Gray.svg'); }
.icon-add { background-image: url('../images/brighteon-social/Icon-Add-Gray.svg'); }
.icon-address-book-o { background-image: url('../images/brighteon-social/Icon-Connect-Gray.svg'); }
.icon-announcements { background-image: url('../images/brighteon-social/Icon-Announcements-Gray.svg'); }
.icon-appearance { background-image: url('../images/brighteon-social/Icon-Appearance-Gray.svg'); }
.icon-arrow-both { background-image: url('../images/brighteon-social/Icon-Arrow-Both-Gray.svg'); }
.icon-arrow-down { background-image: url('../images/brighteon-social/Icon-Arrow-Down-Gray.svg'); }
.icon-arrow-left { background-image: url('../images/brighteon-social/Icon-Arrow-Left-Gray.svg'); }
.icon-arrow-right { background-image: url('../images/brighteon-social/Icon-Arrow-Right-Gray.svg'); }
.icon-arrow-up { background-image: url('../images/brighteon-social/Icon-Arrow-Up-Gray.svg'); }
.icon-attachment { background-image: url('../images/brighteon-social/Icon-Attachment-Gray.svg'); }
.icon-audio-mute { background-image: url('../images/brighteon-social/Icon-Muted-Red.svg'); }
.icon-authorized-apps { background-image: url('../images/brighteon-social/Icon-Apps-Gray.svg'); }
.icon-bell { background-image: url('../images/brighteon-social/Icon-Notification-Gray.svg'); }
.icon-bookmark { background-image: url('../images/brighteon-social/Icon-Bookmark-Gray.svg'); }
.icon-brighteon-feed { background-image: url('../images/brighteon-social/Icon-New-Brighteon-Gray.svg'); }
.icon-clear-big { background-image: url('../images/brighteon-social/Icon-Clear-Big-Gray.svg'); }
.icon-clear {
  background-image: url('../images/brighteon-social/Icon-Clear-Gray.svg');

  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
}
.icon-close {
  background-image: url('../images/brighteon-social/Icon-Close.svg');

  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
}
.icon-development { background-image: url('../images/brighteon-social/Icon-Development-Gray.svg'); }
.icon-direct { background-image: url('../images/brighteon-social/Icon-Messages-Gray.svg'); }
.icon-donate { background-image: url('../images/brighteon-social/Icon-Donate-Gray.svg'); }
.icon-edit-profile { background-image: url('../images/brighteon-social/Icon-Profile-Appearance-Gray.svg'); }
.icon-ellipsis-h { background-image: url('../images/brighteon-social/Icon-Options-Gray.svg'); }
.icon-ellipsis-h-big { background-image: url('../images/brighteon-social/Icon-Options-Big-Gray.svg'); }
.icon-emoji { background-image: url('../images/brighteon-social/Icon-Emoji-Gray.svg'); }
.icon-export { background-image: url('../images/brighteon-social/Icon-Export-Gray.svg'); }
.icon-favorite { background-image: url('../images/brighteon-social/Icon-Favorites-Gray.svg'); }
.icon-favorite-white { background-image: url('../images/brighteon-social/Icon-Favorite-White.svg'); }
.icon-filters { background-image: url('../images/brighteon-social/Icon-Filters-Gray.svg'); }
.icon-follows { background-image: url('../images/brighteon-social/Icon-Following-Gray.svg'); }
.icon-follow-white { background-image: url('../images/brighteon-social/Icon-Follower-White.svg'); }
.icon-globe { background-image: url('../images/brighteon-social/Icon-Visibility-Gray.svg'); }
.icon-hashtags { background-image: url('../images/brighteon-social/Icon-HashTags-Gray.svg'); }
.icon-help { background-image: url('../images/brighteon-social/Icon-Help-Gray.svg'); }
.icon-home { background-image: url('../images/brighteon-social/Icon-Home-Gray.svg'); }
.icon-import { background-image: url('../images/brighteon-social/Icon-Import-Gray.svg'); }
.icon-invite { background-image: url('../images/brighteon-social/Icon-Invite-Gray.svg'); }
.icon-list-ul { background-image: url('../images/brighteon-social/Icon-Lists-Gray.svg'); }
.icon-live-stream { background-image: url('../images/brighteon-social/Icon-LiveStream-Gray.svg'); }
.icon-lock { background-image: url('../images/brighteon-social/Icon-Followers-Gray.svg'); }
.icon-mention-white { background-image: url('../images/brighteon-social/Icon-Reply-White.svg'); }
.icon-menu { background-image: url('../images/brighteon-social/Icon-Menu-Gray.svg'); }
.icon-messages, .icon-envelope { background-image: url('../images/brighteon-social/Icon-Messages-Gray.svg'); }
.icon-news { background-image: url('../images/brighteon-social/Icon-News-Gray.svg'); }
.icon-new-gray { background-image: url('../images/brighteon-social/Icon-New-Gray.svg'); }
.icon-new-post { background-image: url('../images/brighteon-social/Icon-NewPost-Gray.svg'); }
.icon-new-post-white { background-image: url('../images/brighteon-social/Icon-NewPost-White.svg'); }
.icon-notifications { background-image: url('../images/brighteon-social/Icon-Notification-Gray.svg'); }
.icon-poll { background-image: url('../images/brighteon-social/Icon-Poll-Gray.svg'); }
.icon-poll-gray { background-image: url('../images/brighteon-social/Icon-Poll-Notification-Gray.svg'); }
.icon-poll-white { background-image: url('../images/brighteon-social/Icon-Poll-Notification-White.svg'); }
.icon-preferences { background-image: url('../images/brighteon-social/Icon-Preferences-Gray.svg'); }
.icon-profile { background-image: url('../images/brighteon-social/Icon-Profile-Gray.svg'); }
.icon-reply { background-image: url('../images/brighteon-social/Icon-Reply-Gray.svg'); }
.icon-reply-all { background-image: url('../images/brighteon-social/Icon-Reply-Gray.svg'); }
.icon-reply-white { background-image: url('../images/brighteon-social/Icon-Reply-White.svg'); }
.icon-repost-white { background-image: url('../images/brighteon-social/Icon-Repost-White.svg'); }
.icon-resize { background-image: url('../images/brighteon-social/Icon-Resize-Gray.svg'); }
.icon-resize-small { background-image: url('../images/brighteon-social/Icon-Resize-Small-Gray.svg'); }
.icon-retweet { background-image: url('../images/brighteon-social/Icon-Repost-Gray.svg'); }
.icon-search-gray { background-image: url('../images/brighteon-social/Icon-Search-Gray.svg'); }
.icon-selected { background-image: url('../images/brighteon-social/Icon-Selected-Gray.svg'); }
.icon-settings { background-image: url('../images/brighteon-social/Icon-Settings-Gray.svg'); }
.icon-shop { background-image: url('../images/brighteon-social/Icon-Shop-Gray.svg'); }
.icon-signout { background-image: url('../images/brighteon-social/Icon-SignOut-Gray.svg'); }
.icon-star, .icon-heart { background-image: url('../images/brighteon-social/Icon-Favorites-Gray.svg'); }
.icon-thumb-tack { background-image: url('../images/brighteon-social/Icon-Pinned-Gray.svg'); }
.icon-trending { background-image: url('../images/brighteon-social/Icon-Trends-Gray.svg'); }
.icon-two-factor-auth { background-image: url('../images/brighteon-social/Icon-Two-Factor-Auth-Gray.svg'); }
.icon-unlock { background-image: url('../images/brighteon-social/Icon-Unlisted-Gray.svg'); }
.icon-users { background-image: url('../images/brighteon-social/Icon-Following-Gray.svg'); }
.icon-user-plus { background-image: url('../images/brighteon-social/Icon-Follower-Gray.svg'); }
.icon-visibility { background-image: url('../images/brighteon-social/Icon-Visibility-Gray.svg'); }
.icon-warning { background-image: url('../images/brighteon-social/Icon-Warning-Gray.svg'); }
.icon-website { background-image: url('../images/brighteon-social/Icon-Website-Gray.svg'); }

.icon-button.active {
  .icon-star, .icon-heart {
    background-image: url('../images/brighteon-social/Icon-Favorite-Red.svg');
  }

  .icon-retweet {
    background-image: url('../images/brighteon-social/Icon-Reposted-Green.svg');
  }
}
/* Icon Area End */

/* Navigation Bar */

.navigation-bar {
  position: sticky;
  top: 0;
  z-index: 4;

  .navigation-bar__inner {
    width: 1340px;

    & > div {
      justify-content: space-between;
    }

    .search__input {
      color: #666;

      &::placeholder {
        color: #666;
      }
    }
  }

  .navigation-icon {
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .icon-with-badge__badge {
    left: 20px;
  }
}

/* Navigation Bar End */

/* Side Bar */

.left-side-bar-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000c;
  z-index: 9999;
  display: flex;
  top: 0;

  .left-side-bar {
    width: 300px;
    background: white;
    padding: 15px;
    overflow-y: scroll;
  
    .left-side-menu {
      .column-menu {
        padding: 0;
        padding-left: 8px;
        margin-bottom: 5px;
        font-size: 13px;
      }
    
      .column-link {
        padding: 4px 10px;
        font-size: 14px;
    
        &:hover {
          color: $dark1;
          background-color: $light2;
          text-decoration: none;
        }
    
        span {
          vertical-align: super;
        }
      }
    }
  
    .left-menu-icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 10px;
    }

    hr {
      border-color: $light2;
      border-top: none;
    }
  }
}

.right-side-bar-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000c;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  top: 0;

  .right-side-bar {
    width: 270px;
    background: white;
    padding: 15px;
    overflow-y: scroll;
  
    .right-side-menu {
      .column-menu {
        padding: 0;
        padding-left: 8px;
        margin-bottom: 5px;
        font-size: 13px;
      }
    
      .column-link {
        padding: 4px 10px;
        font-size: 14px;
    
        &:hover {
          color: $dark1;
          background-color: $light2;
          text-decoration: none;
        }
    
        span {
          vertical-align: super;
        }
      }

      .profile-name-section {
        flex-direction: column;
        padding-left: 20px;
        font-size: 14px;

        .profile-username {
          margin-top: 10px;
          color: $medium2;
        }
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .stats-section {
        a {
          padding-right: 10px;
        }

        span {
          color: $medium2;
        }
      }
    }
  
    .right-menu-icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 10px;
    }

    hr {
      border-color: $light2;
      border-top: none;
    }
  }
}

/* Side Bar End */

/* homepage timeline */

.columns-area__panels__main {
  max-width: 630px;

  article > .status__wrapper {
    border: 1px solid $medium1;
    border-radius: 4px;
    margin-bottom: 20px;
  
    background-color: white;
  
    .status {
      border-bottom: 0;
    }
  }
}

.status__wrapper {
  border-bottom: 1px solid $light2;

  .status {
    a.status-card {
      flex-direction: column;
    }
    .status__reply {
      a.status-card {
        flex-direction: row;
      }
    }
  }
}

.load-gap {
  border: 1px solid $medium1;
  background-color: white;
  border-radius: 4px;
}

/* homepage timeline end */

/* Announcements/Sponsored Post */

/* Announcements/Sponsored Post End */

/* Widget */

.widget-highlight {
  border-top: solid 3px $highlight1 !important;
}

.widget {
  display: block;
  margin: 0px auto 20px auto;
  position: relative;

  .title {
    display: block;
    margin: 0px auto 0px 0px;
    padding: 10px 15px 8px 15px;
    font-size: 18px;
    font-weight: 500;
    color: $dark2;
    border-bottom: solid 1px $light2;
    font-size: 16px;
  }

  .content {
    display: block;
    padding: 15px;

    .text {
      display: block;
      margin: 0px auto 10px auto;
    }

    .actions {
      a {
        color: $medium2;
      }
    }
  }
}

.latest-news {
  .title {
    margin-bottom: 0;
  }

  .uncensored-news-feed__item {
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $light2;

    .uncensored-news-feed__item__description {
      font-size: 11px;
    }
  }
}

.brighteon-shop {
  .title {
    a {
      text-decoration: none;
      color: black;
    }
  }
  .products {
    flex-wrap: wrap;
    display: block;
    margin: 0px auto 15px auto;

    .product {
      display: inline; 
      width: 50%; 
      float: left; 
      padding-bottom: 8px; 
      border-bottom: solid 1px $light2;
      box-sizing: border-box;

      .product-image img {
        object-fit: contain;
        height: 200px;
      }

      .product-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 36px;
      }

      &:nth-child(odd) {
        padding-right: 8px;
        border-right: solid 1px $light2;
      }

      &:nth-child(even) {
        padding-left: 8px;
        border-left: solid 1px $trans1;
      }

      &:nth-child(-n+2) {
        border-top: solid 1px $light2;
      }

      &:nth-child(2n+3) {
        clear: both;
      }

      div {
        font-size: 12px; 
        font-weight: 700; 
        color: $dark1;
      }

      a {
        display: block; 
        margin: 0px; 
        color: $dark1; 
        text-decoration: none;

        &:hover {
          div { 
            color: $dark1;
          }
        }
      }
    }

    &:after { 
      content: ""; 
      display: table; 
      clear: both; 
    }
  }
}

@media (min-width: 900px) {
  .widget {
    border-radius: 4px;
    background-color: white;
    border: solid 1px $medium1;
  }
}

/* Widget End */

/* Demo style class */

.demo-button {
  display: inline-block;
  max-width: 200px;
  padding: 8px 20px 6px 20px;
  border: none 0px;
  outline: none 0px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none!important;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.demo-button-a {
  background-color: $blue2;
  color: $white !important;

  &:hover {
    background-color: $blue3;
  }
}

.demo-button-b {
  background-color: $medium2;
  color: white !important;
}
.demo-button-c {
  background-color: $red1;
  color: white !important;
}

@media (max-width: 639px) {
  .demo-button {
    font-size: 12px;
    padding: 3px 5px;
  }
}

.widget {
  .actions {
    a {
      color: $medium2;

      &:hover {
        color: $blue2;
      }
    }

    .demo-button {
      margin-right: 8px;
    }
  }
}

@media (min-width: 1000px) {
  .demo-button-b:hover {
    background-color: $blue3 !important;
  }
}

div, a {
  font-family: 'Roboto', arial, helvetica, sans-serif;
}

/* Demo style class end */

/* Other */

.compose-panel {
  overflow: unset;
  height: unset;
  margin-top: 0px;
  width: 350px;
}

.columns-area__panels__pane {
  overflow: unset;
}

.columns-area__panels__pane--navigational {
  position: sticky;
  top: 78px;
}

.status__content__read-more-button {
  font-size: 14px;
  font-weight: 700;
}

.display-name__account {
  color: $medium2;
}

.display-name__description {
  color: $medium2;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 15px;
}

.status__menu-dropdown {
  margin-left: auto;
}

.account__avatar {
  border-radius: 50%;
}

span.display-name {
  bdi {
    .display-name__html {
      font-weight: 700;
    }
  }
}

.status__content__read-more-button {
  color: $medium2;
  font-weight: 700;
}

#NewPostButton {
  margin-top: 20px;
  padding: 10px 30px 10px 50px;
  font-size: 15px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: auto;
}

/* Other End */

/* Compose Reply Form */ 
.app-body, .profile, .statuses {
  .status__reply-box {
    .brighteon-reply-compose.compose-form {
      .compose-form__buttons-wrapper {
        background: none !important;
        border: none !important;
  
        .compose-form__buttons {
          button {
            height: 24px;
            padding: 0;
            border-radius: 50%;
          }
        }
    
        .emoji-picker-dropdown {
          position: relative;
        }
  
        .character-counter__wrapper {
          margin-left: auto;
  
          .character-counter {
            font-size: 12px;
            font-weight: 400;
          }
        }
  
        .compose-form__publish-button-wrapper {
          padding-top: 0;
  
          button {
            font-size: 10px;
            height: 25px;
            padding: 10px;
            line-height: 0;
          }
        }
      }
      .compose-form__autosuggest-wrapper {
        background: none;
    
        textarea {
          background: none;
        }
        .compose-form__modifiers {
          background: none;
        }
  
        border: 1px solid $medium1;
      }
    }
  }
} 
/* Compose Reply Form End */

/* Responsive Header */

.donate-shop-small {
  display: none;
}

@media (max-width: 1024px) {
  .columns-area__panels__pane--compositional {
    display: none;
  }
}

@media (max-width: 900px) {
  .navigation-bar {
    padding-left: 10px;

    .navigation-bar__inner {
      .head-text-link {
        display: none;
      }
    }
  }
}

@media (max-width: 425px) {
  .navigation-bar {
    padding-left: 10px;

    .navigation-bar__inner {
      .head-text-link, .messages-link, .settings-link, .head-icon-link {
        display: none;
      }
    }

    .donate-shop-small {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/* Responsive Header End */

/* New Post Modal */

.new-post-modal-container {
  position: fixed;
  background: rgba(0,0,0,0.8);
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding-top: calc((100vh - 300px) / 2);

  .new-post-modal {
    width: 450px;
    margin: auto;
    position: relative;

    .compose-form.brighteon {
      .autosuggest-textarea {
        min-height: 120px;
  
        textarea {
          max-height: 300px;
          min-height: 120px;
        }
      }
    }

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@media (max-width: 425px) {
  .new-post-modal-container {
    padding-top: 0;
    background: white;

    .new-post-modal {
      width: 100%;
      
      .brighteon.compose-form {
        border: none;
      }
    }
  }
}

/* New Post Modal End */

/* Brighteon TV Widget */
#BrighteonTVEpisodes {
  .Text {
    margin: 0 auto 15px auto;

    a {
      margin: 0px;
      color: $dark1;
      text-decoration: none;

      .Photo {
        margin: 0px auto 5px auto;

        .Thumb {
          margin: 0px auto;
          position: relative;

          img {
            border-radius: 4px;
            width: 100%;
          }

          span {
            padding: 4px 6px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            background-color: $trans2;
            position: absolute;
            right: 5px;
            bottom: 5px;
          }
        }
      }

      .Headline {
        margin: 0px auto;
        font-size: 13px;
        font-weight: 500;
        color: $dark1;
      }
    }

  }
}
/* Brighteon TV Widget End */

/* Notification Dropdown */

.navigation-bar {
  .navigation-bar__inner {
    .notification-bell {
      .drawer__pager {
        .drawer__inner {
          box-shadow: 0px 8px 16px 0px rgb(80 86 118 / 25%);
          border: 1px solid $medium1;

          & > div:not(:first-child) {
            border-top: 1px solid $light2;
          }

          .status__replied-accounts__other {
            cursor: pointer;
          
            &:hover {
              text-decoration: underline;
            }
          }

          .notification__message {
            margin-left: 35px;
            padding-bottom: 8px;
            font-size: 12px;
            span {
              color: $medium2;

              a {
                font-weight: 600;
                color: $dark2;
              }
            }
        
            .notification__reply-icon-wrapper,
            .notification__reblog-icon-wrapper,
            .notification__direct-icon-wrapper,
            .notification__favourite-icon-wrapper {
              position: absolute;
              left: -30px;
            }
          }

          .all-notifications-link-wrapper {
            border-top: 1px solid $light2;
            padding: 10px;
            margin: 5px 0;
            color: $blue2;
            font-size: 12px;
            a {
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

/* Notification Dropdown End */

/* Status Card Start */

.status-card__title {
  font-size: 18px;
  font-weight: 500;
  color: $dark1;
}

.status-card__host, .status-card__description {
  font-size: 13px;
  color: $medium2;
}

/* Status Card End */

/* Sort By Selector Start */

.sort-selector-container {
  display: flex;
  margin-bottom: 20px;

  .sort-selector {
    margin-left: 15px;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: $medium3;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    .sort-by-dropdown {
      position: absolute;
      display: block;
      z-index: 1;
      min-width: 220px;
      top: 30px;
      background-color: white;
      box-shadow: 0px 8px 16px 0px rgb(80 86 118 / 25%);
      border-radius: 5px;
      border: solid 1px $medium1;
      color: $dark1;
      font-size: 14px;

      .option {
        display: flex;
        padding: 14px 8px;
        border-bottom: solid 1px $light2;

        .option-details {
          display: block;

          .title {
            font-weight: bold;
          }
        }
        .icon-container {
          min-width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        &:hover {
          background-color: $light1;
        }
      }
    }
  }
}

/* Sort By Selector End */

/* Profile page Start */

.box-shadow {
  box-shadow: 0px 8px 16px 0px rgb(80 86 118 / 25%);
}

.border {
  margin-top: 7px;
  border-top: 1px solid $medium1;
}

.relationships .card,
.directory__list .directory__card {
  .normal-button {
    font-weight: 500;
    background-color: $medium2;

    &.button--destructive {
      background-color: white;
      border: 1px solid $medium2;
      color: $medium2;
      
      .hover {
        display: none;
      }

      &:hover {
        color: $red1;
        background-color: white;

        .hover {
          display: block;
        }
        .normal {
          display: none;
        }
      }
    }

    &:hover {
      background-color: $blue3;
    }
  }
}

.normal-button {
  font-weight: 500;
  background-color: $blue2;

  &.button--destructive {
    color: $white;
    
    .hover {
      display: none;
    }

    &:hover {
      color: $red1;
      background-color: white;
      border: 1px solid $medium2;

      .hover {
        display: block;
      }
      .normal {
        display: none;
      }
    }
  }

  &:hover {
    background-color: $blue3;
  }

  &.disable:hover {
    background-color: $blue2;
  }
}

.floating-new-post-button {
  background-color: $red1;

  &:hover {
    cursor: pointer;
    background-color: $red1;
  }
}
/* Profile page End */

/* Homepage Trending Tags Start */

.getting-started__trends {
  h4 {
    color: black;
  }

  .trends__item {
    .trends__item__name {
      a, span {
        color: black;
      }
    }
  }
}

/* Homepage Trending Tags End */

/* Notifications Page Start */
.notification-scroll-container {
  border-radius: 4px;
  border: 1px solid $medium1;
  background-color: white;
  padding: 15px;

  .notification {
    &:hover {
      background-color: $light1;
    }
    .notification__content {
      padding: 7px 0 0 5px;
      color: $medium2;

      a {
        color: $medium2;

        bdi > strong {
          font-weight: 700;
        }
      }
    }
    
    .notification__checkbox {
      margin-top: 13px;
      margin-right: 15px;
    }
  
    .notification__date {
      padding-top: 20px;
      margin-right: 30px;
      color: $medium2;
    }

    .notification__message {
      .image-container.multi-accounts {
        background-color: $medium2;
        border-radius: 50%;
        min-width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
      }
    }

    &.unread {
      .notification__message {
        .image-container.multi-accounts {
          background-color: $blue2;
        }

        .unread-notification {
          width: 6px;
          height: 6px;
          top: 0px;
          left: 1px;
          background-color: blue;
          border-radius: 50%;
          border: 2px solid white;
        }
        span {
          color: $blue2;
    
          a {
            color: $blue2;
          }
        }
      }
    }
  }
}

.notification__nav-back {
  border: solid 1px $medium1;
  border-radius: 2px;
  height: 30px;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
}
/* Notifications Page End */

/* News page Start */

.news-container {
  border-radius: 4px;
  border: 1px solid $medium1;
  background-color: white;

  .news-title {
    font-size: 16px;
    font-weight: 800;
    padding: 10px 15px 8px 15px;
    border-bottom: 1px solid $light2;
  }

  .uncensored-news-feed__item {
    border-bottom: 1px solid $light2;

    .uncensored-news-feed__item__headline {
      font-size: 16px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* News page End */

/* Settings Tab Start */

.settings-container {
  .settings-tab {
    cursor: default;
    display: flex;
    flex-shrink: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid $medium1;
  
    .nav-back {
      border: solid 1px #CCD0E4;
      border-radius: 2px;
      height: 30px;
      margin-top: 10px;
    }
  
    .settings-tab-text-container {
      padding-top: 16px;
      padding-left: 15px;
  
      .settings-tab-text {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .settings-panel {
    border-radius: 4px;
    border: 1px solid $medium1;
    background-color: white;

    .settings-nav {
      padding: 10px;
      width: 280px;
      border-right: 1px solid $light2;

      .settings-nav-link-group {
        padding: 0px 0px 5px 0px;
        border-bottom: 1px solid $light2;
        margin-bottom: 10px;

        a {
          padding: 8px 8px 8px 8px;
          font-size: 14px;
          font-weight: 400;
          display: block;
          text-decoration: none;
          border-radius: 4px;

          span {
            margin-left: 16px;
            font-size: 14px;
            font-weight: 400;
            color: $dark1;
            vertical-align: top;
          }

          &:hover {
            background-color: $light2;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .settings-content {
      padding: 40px;
      width: 100%;
      max-width: 100%;
      overflow-x: auto;

      .data-section {
        max-width: 100%;
        overflow-x: auto;
      }
    }

    @media screen and (max-width: 425px) {
      .settings-content {
        padding: 20px;
      }
    }
  }
}

.relationships-setting {
  width: 100%;

  .filter-section {
    width: 100%;
    display: table;
    border-bottom: 1px solid $light2;
    padding-bottom: 35px;

    .filter-column {
      display: table-cell;
      width: 50%;
  
      .filter-container {
        .filter-name {
          display: block;
          margin: 0px auto 15px auto;
          font-weight: 600;
          font-size: 15px;
        }
  
        .filter-options {
          display: block;
          margin: 0px auto 8px auto;
  
          select {
            height: 40px;
            width: 100%;
            margin-bottom: 5px;
            padding: 10px;
            border: solid 1px $medium1;
          }
        }
      }
    }

    .filter-left {
      padding-right: 30px;
    }

    .filter-right {
      padding-left: 30px;
    }
  }

  @media screen and (max-width: 425px) {
    .filter-section {
      .filter-left {
        padding-right: 10px;
      }
      .filter-right {
        padding-left: 10px;
      }
    }
  }

  .action-section {
    padding: 15px 5px;
    border-bottom: solid 1px $light2;
    text-align: right;

    a {
      cursor: pointer;
      color: $blue2;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      margin: 0 5px;
    }
  }

  .data-section {
    .table {
      thead {
        tr > th {
          border-bottom: 1px solid $light2;
        }
      }
      th, td {
        background-color: transparent;
        color: black;

        &:last-child {
          text-align: right;
        }

        &:nth-child(1), &:nth-child(2) {
          width: 20px;
        }

        &:nth-child(3) {
          width: 20px;
          padding-right: 0;
        }

        &:nth-child(4) {
          padding-left: 5px;
        }

        vertical-align: middle;
      }

      tbody {
        tr {
          td {
            &.relationship-display-name {
              a {
                text-decoration: none;
                color: $blue2;

                .relationship-fullname {
                  font-weight: 600;
                }
              }
            }
          }
          &:hover {
            background-color: $light1;
            color: $blue2;

            td {
              &.relationship-display-name {
                div {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }

              color: $blue2;
            }
          }
        }
      }
    }
  }
}
/* Settings Tab End */

.public-layout {
  .profile-timeline-container {
    .status__prepend {
      margin-left: 45px;
      padding-top: 8px;
      font-weight: 600;
    }
  }
}

.big-flex-small-block {
  display: flex;

  @media screen and (max-width: 867px) {
    display: block;
  }
}


/* Chat Conversation Start */
.chat-page {
  .chat-container {
    .chat-box-wrapper {
      border: 1px solid $medium1;
      border-radius: 4px;
      background-color: white;
      height: calc(100vh - 182px);
      min-height: 300px;

      .chat-settings-sidebar {
        width: 340px;
      }
  
      .chat-conversations-sidebar-container {
        .chat-sidebar-top {
          padding: 10px;
          border-bottom: 1px solid $medium1;

          .search-input {
            border: 1px solid $light2;
          }
        }

        .conversation-item-container {
          border-bottom: 1px solid $medium1;
        }
      }
  
      .chat-messages-container {
        border-left: 1px solid $medium1;
        width: 760px;

        .chat-message-header {
          height: 60px;
          border-bottom: 1px solid $medium1;
        }

        .chat-message-scroll-container {
          bottom: 60px;
          top: 60px;

          .chat-bg-secondary {
            color: $dark1;
            background-color: $light2;
          }

          .chat-bg-tertiary {
            color: $white;
            background-color: $blue2;
          }
        }

        .chat-compose-form-container {
          border-top: 1px solid $medium1;
        }
      }
    }
  }
}

.chat-bg-secondary {
  color: $dark1;
  background-color: $light2;
}

.chat-bg-tertiary {
  color: $white;
  background-color: $blue2;
}
/* Chat Conversation End */

.news-video {
  .video {
    position: relative;
    overflow: hidden;
  }
  .video:before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
  .video iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

/* Chat Compose Form Start */

.chat-compose-form-container {
  .autosuggest-textarea__suggestions-wrapper {
    .autosuggest-textarea__suggestions.autosuggest-textarea__suggestions--visible {
      position: absolute;
      bottom: 33px;
      background: darken($ui-base-color, 6%);
      width: 100%;
      box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
      border-radius: 4px 4px 0px 0px;
      color: $inverted-text-color;
      font-size: 14px;
      padding: 6px;

      .autosuggest-textarea__suggestions__item {
        padding: 10px;
        cursor: pointer;
        border-radius: 4px;
    
        &:hover,
        &:focus,
        &:active,
        &.selected {
          background: lighten($ui-base-color, 4%);
        }
  
        .autosuggest-account {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          line-height: 18px;
          font-size: 14px; 
        }
      }
    }
  }
}

/* Chat Compose Form End */
