.circle-rounded {
  border-radius: 50% !important;
}

.max-circle { border-radius: 9999px; }

.d {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  border: 0 solid var(--color_black);
  z-index: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.br1 {
  border-radius: 0.25rem !important;
}

.br1-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.br1-bottom {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.bg-transparent { background-color: transparent !important; }
.bg-white { background-color: white; }
.bg-light1 { background-color: $light1; }
.bg-medium1 { background-color: $medium1; }
.bg-medium2 { background-color: $medium2; }
.bg-blue2 { background-color: $blue2; }
.bg-red1 { background-color: $red1; }

.overflow-hidden {
  overflow: hidden !important;
}

.m0 { margin: 0!important; }
.m-auto { margin: auto; }

.mb-auto { margin-bottom: auto; }
.mb2 { margin-bottom: 0.5rem !important; }
.mb3 { margin-bottom: 1rem !important; }
.mb4 { margin-bottom: 1.5rem !important; }
.mb5 { margin-bottom: 2rem !important; }
.mb5px { margin-bottom: 5px; }
.mb10px { margin-bottom: 10px; }
.mb15px { margin-bottom: 15px; }
.mb20px { margin-bottom: 20px; }
.mb30px { margin-bottom: 30px; }
.mb60px { margin-bottom: 60px; }

.ml-auto { margin-left: auto; }
.ml1 { margin-left: 0.25rem !important; }
.ml2 { margin-left: 0.5rem !important; }

.ml-neg-25px { margin-left: -25px; }
.ml-neg-15px { margin-left: -15px; }
.ml3px { margin-left: 3px; }
.ml5px { margin-left: 5px; }
.ml10px { margin-left: 10px; }
.ml20px { margin-left: 20px; }

.mr-auto { margin-right: auto; }
.mr1 { margin-right: 0.25rem !important; }
.mr2 { margin-right: 0.5rem !important; }
.mr3 { margin-right: 1rem !important; }

.mr5px { margin-right: 5px; }
.mr10px { margin-right: 10px; }
.mr15px { margin-right: 15px; }

.mt-auto { margin-top: auto; }
.mt0 { margin-top: 0; }
.mt1 { margin-top: 0.25rem !important; }
.mt2 { margin-top: 0.5rem !important; }
.mt3 { margin-top: 1rem !important; }
.mt4 { margin-top: 1.5rem !important; }
.mt5 { margin-top: 2rem !important; }
.mt-neg-5px { margin-top: -5px; }
.mt4px { margin-top: 4px; }
.mt5px { margin-top: 5px; }
.mt7px { margin-top: 7px; }
.mt10px { margin-top: 10px; }
.mt15px { margin-top: 15px; }
.mt20px { margin-top: 20px; }
.mt40px { margin-top: 40px; }
.m10px { margin: 10px; }
.my15px {
  margin-top: 15px;
  margin-bottom: 15px;
}

.pt1 { padding-top: 0.25rem !important; }
.pt2px { padding-top: 2px; }
.pt10px { padding-top: 10px; }

.px5px {
  padding-left: 5px;
  padding-right: 5px;
}

.px7px {
  padding-left: 7px;
  padding-right: 7px;
}

.px10px {
  padding-left: 10px;
  padding-right: 10px;
}

.px15px {
  padding-left: 15px;
  padding-right: 15px;
}

.px30px {
  padding-left: 30px;
  padding-right: 30px;
}

.px45px {
  padding-left: 45px;
  padding-right: 45px;
}

.py2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py15px {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pl10px { padding-left: 10px; }
.pl15px { padding-left: 15px; }
.pl20px { padding-left: 20px; }
.pl25px { padding-left: 25px; }
.pl35px { padding-left: 35px; }
.pl40px { padding-left: 40px; }
.pl50px { padding-left: 50px; }

.pl35px-sm { padding-left: 35px; }

.pr5px { padding-right: 5px; }
.pr10px { padding-right: 10px; }
.pr15px { padding-right: 15px; }
.pr25px { padding-right: 25px; }
.pr35px { padding-right: 35px; }
.pr50px { padding-right: 50px; }

.pr35px-sm { padding-right: 35px; }

.pb5px { padding-bottom: 5px; }
.pb15px { padding-bottom: 15px; }
.pb30px { padding-bottom: 30px; }

.p3 { padding: 1rem !important; }

.p2px { padding: 2px; }
.p3px { padding: 3px; }
.p5px { padding: 5px; }
.p10px { padding: 10px; }
.p15px { padding: 15px; }

.d-none { display: none !important; }
.d-flex { display: flex !important; }
.d-flex-sm { display: flex !important; }
.d-inline-block { display: inline-block; }

.v-hidden { visibility: hidden; }

.flex-grow-1 { flex-grow: 1; }

.flex-row { flex-direction: row; }
.flex-row-reverse { flex-direction: row-reverse; }

.flex-column {
  flex-direction: column;
}

.flex-fill {
  flex: 1;
}

.flex-normal {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-shrink0 { flex-shrink: 0; }
.flex-shrink1 { flex-shrink: 1; }

.flex-wrap { flex-wrap: wrap; }

.relative-fill {
  position: relative;
  height: 100%;
}

.absolute-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-overflow-ellipsis {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-overflow-ellipsis2 { text-overflow: ellipsis; }

.white-space-no-wrap {
  white-space: nowrap;
}

.decoration-none {
  text-decoration: none !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.w-50 { width: 50%; }
.w-50-sm { width: 50%; }
.w-100 { width: 100%; }
.w-10px { width: 10px; }
.w-29px { width: 29px; }
.w-40px { width: 40px; }
.w-62px { width: 62px; }
.w-90px { width: 90px; }
.w-198px { width: 198px; }
.w-270px { width: 270px; }
.w-340px { width: 340px; }
.w-430px { width: 430px; }
.min-w-60px { min-width: 60px; }
.min-w-250px { min-width: 250px; }
.max-w-80pc { max-width: 80%; }
.max-w-80pc-30px { max-width: calc(80% - 30px); }
.max-w-100pc { max-width: 100%; }
.max-w-100pc-42px { max-width: calc(100% - 42px); }
.max-w-100pc-68px { max-width: calc(100% - 68px); }
.max-w-100pc-78px { max-width: calc(100% - 78px); }
.max-w-100pc-86px { max-width: calc(100% - 86px); }
.max-w-200px { max-width: 200px; }
.max-w-400px { max-width: 400px; }
.max-w-900px { max-width: 900px; }

.h-auto { height: auto; }
.h-0 { height: 0; }
.h-100vh { height: 100vh; }
.h-100 { height: 100%; }
.h-10px { height: 10px; }
.h-24px { height: 24px; }
.h-29px { height: 29px; }
.h-34px { height: 34px; }
.h-35px { height: 35px; }
.h-40px { height: 40px; }
.h-53px { height: 53px; }
.h-60px { height: 60px; }
.h-62px { height: 62px; }
.h-200px { height: 200px; }
.h-100vh-124px { height: calc(100vh - 124px); }
.min-h-53px { min-height: 53px; }
.min-h-58px { min-height: 58px; }
.min-h-150px { min-height: 150px; }
.min-h-100vh { min-height: 100vh; }
.min-h-100vh-90px { min-height: calc(100vh - 90px); }
.min-h-100vh-200px { min-height: calc(100vh - 200px); }
.max-h-45px { max-height: 45px; }
.max-h-200px { max-height: 200px; }
.max-h-300px { max-height: 300px; }
.max-h-500px { max-height: 500px; }
.max-h-80vh { max-height: 80vh; }
.max-h-100vh-76px { max-height: calc(100vh - 76px); }
.max-h-100pc-37px { max-height: calc(100% - 37px); }

.color-gray { color: #333333; }
.color-blue { color: $blue2; }
.color-medium2 { color: $medium2; }
.color-black { color: black; }
.color-white { color: white; }
.color-red { color: red; }
.color-dark { color: $dark-text-color; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
h5 {
  font-size: 0.83em;
}
h6 {
  font-size: 0.67em;
}

.font-weight-bold {
  font-weight: bold;
}

.font-style-italic {
  font-style: italic;
}

.padded-container {
  padding: 15px;
}

.float-right {
  float: right;
}

.fg-color-theme {
  color: $theme-color;
}

.border-0 {
  border: none !important;
}

.border-none {
  border: none;
}

.back-none {
  background: none !important;
}

.button-icon {
  background-position: center center;
  background-repeat: no-repeat;
}

.circle {
  border-radius: 50%;
}

.normal-button {
  background-color: #3366FF;
  text-transform: none;
  height: 30px;
  font-size: 12px;
  line-height: 30px;

  &:hover {
    background-color: $blue3;
  }
}

.radius-box {
  border-radius: 4px;
  padding: 15px;
  background-color: $white;
  border: 1px solid $medium1;
}

.pos-relative { position: relative; }
.pos-absolute { position: absolute; }
.pos-fixed    { position: fixed; }
.pos-sticky   { position: sticky; }

.vertical-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.font-size-24px { font-size: 24px; }
.font-size-20px { font-size: 20px; }
.font-size-18px { font-size: 18px; }
.font-size-15px { font-size: 15px; }
.font-size-14px { font-size: 14px; }
.font-size-12px { font-size: 12px; }

.font-weight-500 { font-weight: 500; }
.font-weight-600 { font-weight: 600; }

.border-1px { border-width: 1px; }
.border-medium1-1 { border: 1px solid $medium1; }
.border-medium1-2 { border: 2px solid $medium1; }
.border-top-1px { border-top-width: 1px; }
.border-top-medium1-1 { border-top: 1px solid $medium1; }
.border-right-1px { border-right: 1px solid; }
.border-right-4px { border-right: 4px solid; }
.border-bottom-medium1-1 { border-bottom: 1px solid $medium1; }
.border-left-medium1-1 { border-left: 1px solid $medium1; }
.border-left-medium1-1-sm { border-left: 1px solid $medium1; }

.border-color-red { border-color: $red1; }
.border-color-white { border-color: white; }
.border-color-transparent { border-color: transparent; }

.border-solid { border-style: solid; }

.overflow-y-hidden { overflow-y: hidden; }
.overflow-y-scroll { overflow-y: scroll; }
.overflow-y-auto { overflow-y: auto; }
.overflow-x-hidden { overflow-x: hidden; }
.overflow-x-scroll { overflow-x: scroll; }
.overflow-wrap-break-word { overflow-wrap: break-word; }

.flex-shrink {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
}

.right-0 { right: 0; }
.right-50px { right: 50px; }

.left-neg-15px { left: -15px; }
.left-0 { left: 0; }
.left-10px { left: 10px; }
.left-20px { left: 20px; }
.left-50px { left: 50px; }

.bottom-0 { bottom: 0; }

.top-min15px { top: -15px; }
.top-0 { top: 0; }
.top-7px { top: 7px; }
.top-8px { top: 8px; }
.top-17px { top: 17px; }
.top-60px { top: 60px; }
.top-50pc { top: 50%; }

.border-box { box-sizing: border-box; }

.radius-rounded-20px { border-radius: 20px; }
.radius-rounded-8px { border-radius: 8px; }
.radius-rounded-4px { border-radius: 4px; }

.resize-none { resize: none; }

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.outline-none { outline: none; }

.line-height-15 { line-height: 1.5em; }
.line-height-125 { line-height: 1.25rem; }
.line-height-2 { line-height: 2em; }

.cursor-pointer { cursor: pointer; }
.cursor-not-allowed { cursor: not-allowed; }

.box-shadow-none { box-shadow: none; }
.box-shadow-popover { box-shadow: 0 0 15px -5px rgba(0,0,0,0.15); }
.box-shadow-emoticon { box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 1px; }

.opacity05 { opacity: 0.5; }
.underline-onHover:hover { text-decoration: underline; }

.no-scrollbar::-webkit-scrollbar { display: none; /* Chrome/Safari/Webkit */ }

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.object-fit-cover { object-fit: cover; }

.bg-subtle-onhover:hover { background-color: $light1; }

.z0 { z-index: 0; }
.z2 { z-index: 2; }
.z3 { z-index: 3; }
.z4 { z-index: 4; }

.modal:after {
  content: '';
  display: block;
  position: absolute;
  width: 70px;
  height: 6px;
  background-color: var(--solid_color_primary);
  border-radius: var(--radius-circle);
  left: calc(100% / 2 - 35px);
  top: -20px;
}

.brighteon-scrollbar::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
}

.brighteon-scrollbar::-webkit-scrollbar-track {
  background-color: $light2;
}

.brighteon-scrollbar::-webkit-scrollbar-thumb {
  background-color: $medium1;
}

.accent-gray {
  accent-color: $medium2
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.fill-navigation {
  fill: white;
  color: white;
}

@media screen and (max-width: 768px) {
  .app-body {
    .compose-form.brighteon.mobile-hide {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  .w-50-sm {
    width: 100%;
  }
  .border-left-medium1-1-sm {
    border-left: none;
  }
  .pl35px-sm {
    padding-left: 0;
  }
  .pr35px-sm {
    padding-right: 0;
  }
  .d-flex-sm {
    display: block !important;
  }
}

.tooltip:hover .tooltiphtml {
  visibility: visible;

  width: max-content;
  max-width: 300px;
  bottom: 150%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}

.tooltiphtml {
  visibility: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $medium2 transparent transparent transparent;
  }
}

@media (max-width: 1439px) {
  .d-none-mobile {
    display: none !important;
  }
}

@media (max-width: 630px) {
  .desktop-container {
    display: none;
  }
}

@media (min-width: 630px) {
  .mobile-container {
    display: none;
  }
}

.gold-background {
  background: #eab929;
  background: -moz-linear-gradient(-45deg, #eab929 0%, #fffd91 34%, #fffd91 68%, #fffd91 68%, #eab929 100%);
  background: -webkit-linear-gradient(-45deg, #eab929 0%, #fffd91 34%, #fffd91 68%, #fffd91 68%, #eab929 100%);
  background: linear-gradient(135deg, #eab929 0%, #fffd91 34%, #fffd91 68%, #fffd91 68%, #eab929 100%);
}
