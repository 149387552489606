// Dependent colors
$black: #000000;
$white: #ffffff;

$light1: #F7F9FF;
$light2: #E4EAF9;

$dark1: #202648;
$dark2: #120130;

$medium1: #CCD0E4;
$medium2: #797C8B;
$medium3: #505676;

$blue2: #3366FF;
$blue3: #557FFC;

$red1: #E01F3A;

$highlight1: #FFCC00;

$trans1: rgba(0,0,0,0);
$trans2: rgba(0,0,0,.8);

$classic-base-color: #282c37;
$classic-primary-color: #9baec8;
$classic-secondary-color: #d9e1e8;
$classic-highlight-color: #2b90d9;

// Differences
$success-green: lighten(#3c754d, 8%);

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: #b0c0cf;
$ui-primary-color: #9bcbed;
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: #08083f;

$primary-text-color: $black !default;
$darker-text-color: $white !default;
$dark-text-color: #08083f;
$action-button-color: #000000;
$secondary-text-color: $white;

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: #444b5d;

//Newly added colors
$account-background-color: $white !default;
$theme-color: #2040a4;

$default-placeholder-color: #757575;

//Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}

$emojis-requiring-inversion: 'chains';

$active-retweet-color: #4b0082;
