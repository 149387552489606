.button.button-brighteon {
    background-color: #e1423b;
}

.simple_form.alternative-login {
    a.button-brighteon {
        background-color: #e1423b;
    }
}

